<template>
  <div>
    <div class="d-flex mt-6">
      <v-subheader class="mr-3 align-start"> 登录页LOGO</v-subheader>
      <div>
        <v-img
          contain
          class="mx-6"
          max-height="100"
          max-width="100"
          :src="logoUrl ? logoUrl : `${baseUrl}/upload/login/logo.png`"
          lazy-src="../../../assets/beforeUpload.png"
        ></v-img>
        <span class="pl-6 support-style">
          仅支持png格式
        </span>
      </div>
      <v-file-input
        class="upload"
        hide-input
        accept=".png"
        label="File input"
        @change="uploadLogo"
      ></v-file-input>
    </div>
    <v-form class="mt-4" :model="form" v-model="valid" ref="form">
      <v-row align="center" style="border-bottom:none !important;">
        <v-col cols="1">
          <v-subheader>是否显示logo</v-subheader>
        </v-col>
        <v-col>
          <v-checkbox class="v-label" v-model="showLogo"></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>是否显示系统名称</v-subheader>
        </v-col>
        <v-col>
          <v-checkbox class="v-label" v-model="showSystemName"></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>是否显示认证名称</v-subheader>
        </v-col>
        <v-col>
          <v-checkbox class="v-label" v-model="showLoginName"></v-checkbox>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 登录页标题</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field rows="1" v-model="logoDesc"></v-text-field>
        </v-col>
      </v-row>

      <v-row align="start" class="mt-4">
        <v-col cols="1">
          <v-subheader> 登录页样式</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="loginFontColor"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="loginFontMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="loginFontStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="loginFontColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">登录页中字体颜色</span>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="loginHighLight"
            hide-details
            class="ma-0 pa-0"
            solo
          >
            <template v-slot:append>
              <v-menu
                v-model="HighLightMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div :style="loginHighLightStyle" v-on="on" />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="loginHighLight" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <span class="hint-style">登录页中高亮颜色</span>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader>自定义css</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="loginStyle"
            persistent-hint
            hint="登录页自定义样式"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- 普通认证方式 -->
      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">普通认证方式</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>
      <!-- 输入框登录方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 登录方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="firstLogin"
            :items="firstLogins"
            item-text="name"
            item-value="external_id"
            required
            :rules="
              (firstLogin != null && firstLogin !== '不启用') ||
              (firstLink != null && firstLink !== '不启用')
                ? []
                : rules.hasLogin
            "
            @change="firstLoginSel(firstLogin)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="secondLogin"
            :items="secondLogins"
            item-text="name"
            item-value="external_id"
            :disabled="firstLogin == null || firstLogin === '不启用'"
            @change="secondLoginSel(secondLogin)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="thirdLogin"
            :items="thirdLogins"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLogin == null ||
                firstLogin === '不启用' ||
                secondLogin == null ||
                secondLogin === '不启用'
            "
            @change="thirdLoginSel(thirdLogin)"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 其他登录方式 -->
      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">其他认证方式</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 其他登录方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="firstLink"
            :items="firstLinks"
            item-text="name"
            item-value="external_id"
            required
            :rules="
              (firstLogin != null && firstLogin !== '不启用') ||
              (firstLink != null && firstLink !== '不启用')
                ? []
                : rules.hasLogin
            "
            @change="firstLinkSel(firstLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="secondLink"
            :items="secondLinks"
            item-text="name"
            item-value="external_id"
            :disabled="firstLink == null || firstLink === '不启用'"
            @change="secondLinkSel(secondLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="thirdLink"
            :items="thirdLinks"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLink == null ||
                firstLink === '不启用' ||
                secondLink == null ||
                secondLink === '不启用'
            "
            @change="thirdLinkSel(thirdLink)"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="forthLink"
            :items="forthLinks"
            item-text="name"
            item-value="external_id"
            :disabled="
              firstLink == null ||
                firstLink === '不启用' ||
                secondLink == null ||
                secondLink === '不启用' ||
                thirdLink == null ||
                thirdLink === '不启用'
            "
            @change="forthLinkSel(forthLink)"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 上下文认证方式 -->
      <v-row align="center" class="ml-0 mt-8" v-if="wechatMethods.length > 0">
        <v-subheader style="font-size:18px">上下文认证方式</v-subheader>
        &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>

      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 微信</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="wechatMethod"
            :items="wechatMethods"
            item-text="name"
            item-value="external_id"
            :disabled="wechatMethods.length === 1"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 企业微信上下文认证方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 企业微信</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="wechatEnterpriseMethod"
            :items="wechatEnterpriseMethods"
            item-text="name"
            item-value="external_id"
            :disabled="wechatEnterpriseMethods.length === 1"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 钉钉上下文认证方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 钉钉</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="dingTalkMethod"
            :items="dingTalkMethods"
            item-text="name"
            item-value="external_id"
            :disabled="dingTalkMethods.length === 1"
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 飞书上下文认证方式 -->
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 飞书</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="larkMethod"
            :items="larkMethods"
            item-text="name"
            item-value="external_id"
            :disabled="larkMethods.length === 1"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">登录框</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row align="center">
        <v-col cols="1">
          <v-subheader>
            输入框提示
          </v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="usernamePlaceholder"
            required
            persistent-hint
            hint="用户名文案"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="passwordPlaceholder"
            required
            persistent-hint
            hint="密码文案"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="verifycodePlaceholder"
            required
            persistent-hint
            hint="验证码文案"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="1">
          <v-subheader> 登录按钮</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="loginSubmit"
            required
            persistent-hint
            hint="登录按钮文案"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" class="ml-0 mt-8">
        <v-subheader style="font-size:18px">温馨提示</v-subheader> &nbsp;
        <v-divider class="ml-3"></v-divider>
      </v-row>
      <v-row style="border-bottom:none!important;" align="center">
        <v-col cols="1">
          <v-subheader> 温馨提示</v-subheader>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            v-model="showUseInstruction"
            @change="warmNoticeSel(showUseInstruction)"
            label="启用温馨提示"
          ></v-checkbox>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            v-model="inheritPc"
            @change="inheritPcSel(inheritPc)"
            label="温馨提示展示方式与pc端同步"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row align="center" v-if="showUseInstruction">
        <v-col cols="1">
          <v-subheader>温馨提示展示方式</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="noticeDisplay"
            :items="noticeDisplays"
            item-text="name"
            item-value="id"
            @change="noticeDisplaySel(noticeDisplay)"
            persistent-hint
            hint="温馨提示内容展示方式"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row align="center" v-if="showUseInstruction">
        <v-col cols="1">
          <v-subheader> 温馨提示标题</v-subheader>
        </v-col>

        <v-col cols="2">
          <v-text-field
            rows="1"
            v-model="useInstructionTitle"
            required
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" v-if="showUseInstruction">
        <v-col cols="1">
          <v-subheader> 温馨提示内容</v-subheader>
        </v-col>

        <v-col cols="6">
          <v-textarea
            rows="1"
            auto-grow
            v-model="useInstruction"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import {
  WechatFilter,
  WechatEnterpriseFilter,
  DingTalkFilter,
  LarkFilter,
  hasVal
} from "@/util/contextAuth";

export default {
  name: "MLoginSetting",
  data() {
    let isHasLogin = () => {
      if (
        this.firstLogin !== "不启用" ||
        this.qrcode !== "不启用" ||
        this.firstLink !== "不启用"
      ) {
        return true;
      } else {
        return false;
      }
    };

    return {
      valid: true,
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        hasLogin: [
          v =>
            isHasLogin(v) ||
            "登录方式、扫码登录方式、其他登录方式中至少选择一种登录模式"
        ]
      },
      logoUrl: null,
      tab: null,
      logoDesc: "",
      useInstructionTitle: "",
      useInstruction: "",
      loginSubmit: "",
      usernamePlaceholder: "",
      passwordPlaceholder: "",
      verifycodePlaceholder: "",
      baseUrl: null,
      showUseInstruction: false,
      inheritPc: false,
      allSources: [],
      allMethods: [],
      linkMethods: [],
      allLoginMehods: [],
      firstLogin: "不启用",
      secondLogin: "不启用",
      thirdLogin: "不启用",
      firstLogins: ["不启用"],
      secondLogins: ["不启用"],
      thirdLogins: ["不启用"],
      firstLink: "不启用",
      secondLink: "不启用",
      thirdLink: "不启用",
      forthLink: "不启用",
      firstLinks: ["不启用"],
      secondLinks: ["不启用"],
      thirdLinks: ["不启用"],
      forthLinks: ["不启用"],
      loginFontColor: "#F1F3F5",
      loginFontMenu: false,
      loginHighLight: "#F30707",
      HighLightMenu: false,
      noticeDisplay: "dynamic",
      noticeDisplays: [
        { id: "dynamic", name: "可控展示" },
        { id: "staticState", name: "静态展示" }
      ],
      loginStyle: "",
      showLogo: false,
      showSystemName: false,
      showLoginName: false,
      wechatMethod: "", // 微信上下文认证方式
      wechatMethods: [],
      wechatEnterpriseMethod: "", // 企业微信上下文认证方式
      wechatEnterpriseMethods: [],
      dingTalkMethod: "", // 钉钉上下文认证方式
      dingTalkMethods: [],
      larkMethod: "", // 飞书上下文认证方式
      larkMethods: []
    };
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.fetchSource();
    await this.fetchMethods();
    await this.dealQrcode();
    await this.dealLoginMehods();
    await this.dealLinkMehods();
    await this.getPreLoginStyle();
  },
  methods: {
    noticeDisplaySel(noticeDisplay) {
      this.noticeDisplay = noticeDisplay;
    },
    // 输入框登录方式
    firstLoginSel(firstLogin) {
      if (firstLogin != null || firstLogin !== "不启用") {
        this.firstLogin = firstLogin;
        this.secondLogin = this.secondLogins[0];
        this.thirdLogin = this.thirdLogins[0];

        this.secondLogins = this.firstLogins.filter(
          item => item.external_id !== firstLogin
        );
      } else {
        this.firstLogin = "不启用";
        this.secondLogin = "不启用";
        this.secondLogin = "不启用";
      }
    },
    secondLoginSel(secondLogin) {
      if (secondLogin != null || secondLogin !== "不启用") {
        this.secondLogin = secondLogin;
        this.thirdLogin = this.thirdLogins[0];
        this.secondLogins = this.firstLogins.filter(
          item => item.external_id !== this.firstLogin
        );

        this.thirdLogins = this.secondLogins.filter(
          item => item.external_id !== secondLogin
        );
      } else {
        this.secondLogin = "不启用";
        this.thirdLogin = "不启用";
      }
    },
    thirdLoginSel(thirdLogin) {
      if (thirdLogin != null || thirdLogin !== "不启用") {
        this.thirdLogin = thirdLogin;
        this.thirdLogins = this.secondLogins.filter(
          item => item.external_id !== this.secondLogin
        );
      } else {
        this.thirdLogin = "不启用";
      }
    },
    // 其他登录方式
    firstLinkSel(firstLink) {
      if (firstLink != null || firstLink !== "不启用") {
        this.firstLink = firstLink;
        this.secondLink = this.secondLinks[0];
        this.thirdLink = this.thirdLinks[0];
        this.forthLink = this.forthLinks[0];
        this.secondLinks = this.firstLinks.filter(
          item => item.external_id !== firstLink
        );
      } else {
        this.firstLink === "不启用";
        this.secondLink === "不启用";
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    secondLinkSel(secondLink) {
      if (secondLink != null || secondLink !== "不启用") {
        this.secondLink = secondLink;
        this.thirdLink = this.thirdLinks[0];
        this.secondLinks = this.secondLinks.filter(
          item => item.external_id !== this.firstLink
        );

        this.thirdLinks = this.secondLinks.filter(
          item => item.external_id !== secondLink
        );
      } else {
        this.secondLink === "不启用";
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    thirdLinkSel(thirdLink) {
      if (thirdLink != null || thirdLink !== "不启用") {
        this.thirdLink = thirdLink;
        this.forthLink = this.forthLinks[0];
        this.thirdLinks = this.thirdLinks.filter(
          item => item.external_id !== this.secondLink
        );

        this.forthLinks = this.thirdLinks.filter(
          item => item.external_id !== thirdLink
        );
      } else {
        this.thirdLink === "不启用";
        this.forthLink === "不启用";
      }
    },
    forthLinkSel(forthLink) {
      if (forthLink != null || forthLink !== "不启用") {
        this.forthLink = forthLink;
        this.forthLinks = this.thirdLinks.filter(
          item => item.external_id !== this.thirdLink
        );
      } else {
        forthLink === "不启用";
      }
    },
    changeLoginFontColor(loginFontColor) {
      this.loginFontColor = loginFontColor.substr(0, 7);
    },
    changeLoginHighLight(loginHighLight) {
      this.loginHighLight = loginHighLight.substr(0, 7);
    },
    warmNoticeSel(showUseInstruction) {
      this.showUseInstruction = showUseInstruction;
      if (showUseInstruction) {
        this.inheritPc = false;
      }
    },
    inheritPcSel(inheritPc) {
      this.inheritPc = inheritPc;
      if (inheritPc) {
        this.showUseInstruction = false;
      }
    },
    uploadLogo(value) {
      let formData = new FormData();
      formData.append("file", value);

      let reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.logoUrl = reader.result;
      };

      return this.$http
        .post(`api/upload/logo`, formData)
        .delegateTo(api_request)
        .then(() => {
          return this.$snackbar.showMessage({
            content: `上传成功`,
            isError: false
          });
        })
        .catch(({ code, message }) => {
          throw `上传失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    getPreLoginStyle() {
      return this.$http
        .get(`api/settings/mobile_login`)
        .delegateTo(api_request)
        .then(res => {
          if (Object.keys(res).length === 0) {
            return;
          }
          let {
            showLogo,
            showSystemName,
            showLoginName,
            logoDesc,
            showUseInstruction,
            inheritPc,
            useInstructionTitle,
            useInstruction,
            wechatMethod,
            wechatEnterpriseMethod,
            dingTalkMethod,
            larkMethod,
            tabs,
            otherLinks,
            loginSubmit,
            usernamePlaceholder,
            passwordPlaceholder,
            verifycodePlaceholder,
            noticeDisplay,
            loginStyle,
            loginFrame: { loginFontColor, loginHighLight }
          } = res;

          this.wechatMethod = wechatMethod
            ? wechatMethod
            : this.wechatMethods[0];
          this.wechatEnterpriseMethod = wechatEnterpriseMethod
            ? wechatEnterpriseMethod
            : this.wechatEnterpriseMethods[0];
          this.dingTalkMethod = dingTalkMethod
            ? dingTalkMethod
            : this.dingTalkMethods[0];
          this.larkMethod = larkMethod ? larkMethod : this.larkMethods[0];

          this.showLogo = showLogo ? true : false;
          this.showSystemName = showSystemName ? true : false;
          this.showLoginName = showLoginName ? true : false;
          this.noticeDisplay = noticeDisplay ? noticeDisplay : "dynamic";
          this.loginStyle = loginStyle;
          this.logoDesc = logoDesc;
          this.showUseInstruction = showUseInstruction;
          this.inheritPc = inheritPc;
          this.useInstructionTitle = useInstructionTitle;
          this.useInstruction = useInstruction;
          this.loginSubmit = loginSubmit;
          this.usernamePlaceholder = usernamePlaceholder;
          this.passwordPlaceholder = passwordPlaceholder;
          this.verifycodePlaceholder = verifycodePlaceholder;
          this.firstLogin =
            tabs && tabs[0] && tabs[0].id ? tabs[0].id : this.firstLogins[0];
          this.secondLogin =
            tabs && tabs[1] && tabs[1].id ? tabs[1].id : this.secondLogins[0];
          this.thirdLogin =
            tabs && tabs[2] && tabs[2].id ? tabs[2].id : this.thirdLogins[0];
          // 其他登录方式
          this.firstLink =
            otherLinks && otherLinks[0] && otherLinks[0].id
              ? otherLinks[0].id
              : this.firstLinks[0];
          this.secondLink =
            otherLinks && otherLinks[1] && otherLinks[1].id
              ? otherLinks[1].id
              : this.secondLinks[0];
          this.thirdLink =
            otherLinks && otherLinks[2] && otherLinks[2].id
              ? otherLinks[2].id
              : this.thirdLinks[0];
          this.forthLink =
            otherLinks && otherLinks[3] && otherLinks[3].id
              ? otherLinks[3].id
              : this.forthLinks[0];

          if (loginFontColor && loginFontColor.length === 4) {
            this.loginFontColor = loginFontColor.concat(
              loginFontColor.substr(1, 3)
            );
          } else {
            this.loginFontColor = loginFontColor;
          }

          if (loginHighLight && loginHighLight.length === 4) {
            this.loginHighLight = loginHighLight.concat(
              loginHighLight.substr(1, 3)
            );
          } else {
            this.loginHighLight = loginHighLight;
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    modifyMLoginStyle() {
      if (this.$refs.form.validate()) {
        let links = [
          this.firstLink,
          this.secondLink,
          this.thirdLink,
          this.forthLink
        ];
        let tabss = [this.firstLogin, this.secondLogin, this.thirdLogin];
        let allLoginMehods = this.allSources.concat(this.allMethods);
        let tabs = this.getArrEqual(tabss, allLoginMehods);
        let otherLinks = this.getArrEqual(links, allLoginMehods);
        let wechatMethod = hasVal(this.wechatMethod) ? this.wechatMethod : null;

        let wechatEnterpriseMethod = hasVal(this.wechatEnterpriseMethod)
          ? this.wechatEnterpriseMethod
          : null;

        let dingTalkMethod = hasVal(this.dingTalkMethod)
          ? this.dingTalkMethod
          : null;

        let larkMethod = hasVal(this.larkMethod) ? this.larkMethod : null;

        let payload = {
          showLogo: this.showLogo,
          showSystemName: this.showSystemName,
          showLoginName: this.showLoginName,
          tabs,
          otherLinks,
          wechatMethod,
          wechatEnterpriseMethod,
          dingTalkMethod,
          larkMethod,
          showUseInstruction: this.showUseInstruction,
          inheritPc: this.inheritPc,
          useInstruction: this.useInstruction,
          usernamePlaceholder: this.usernamePlaceholder,
          passwordPlaceholder: this.passwordPlaceholder,
          verifycodePlaceholder: this.verifycodePlaceholder,
          loginSubmit: this.loginSubmit,
          noticeDisplay: this.noticeDisplay,
          logoDesc: this.logoDesc,
          useInstructionTitle: this.useInstructionTitle,
          loginStyle: this.loginStyle,
          loginFrame: {
            loginFontColor: this.loginFontColor,
            loginHighLight: this.loginHighLight
          }
        };

        return this.$http
          .patch(`api/settings/mobile_login`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$emit("sonVal", false);
            return this.$snackbar.showMessage({
              content: `保存成功`,
              isError: false
            });
          })
          .catch(({ code, message }) => {
            throw `保存失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    getArrEqual(arr2, arr1) {
      let newArr = [];
      for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr1.length; j++) {
          if (arr1[j].external_id === arr2[i]) {
            let curItem = {
              id: arr1[j].external_id,
              name: arr1[j].name
            };
            newArr.push(curItem);
          }
        }
      }
      return newArr;
    },
    fetchSource() {
      return this.$http
        .get(`api/source/list?offset=0&limit=1000`)
        .delegateTo(api_request)
        .then(res => {
          this.allSources = res.sources.filter(item => item.enabled);
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchMethods() {
      return this.$http
        .get(`api/method/list?offset=0&limit=1000`)
        .delegateTo(api_request)
        .then(res => {
          this.allMethods = res.sources.filter(item => item.enabled);
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    dealLoginMehods() {
      let allLoginMehods = this.allSources.concat(this.allMethods);
      this.firstLogins = allLoginMehods.filter(
        item =>
          item.type === "SMS" ||
          item.type === "PASSWORD" ||
          item.type === "FNU_LDAP" ||
          item.type === "RADIUS" ||
          item.type === "LDAP" ||
          item.type === "LDAP" ||
          item.type === "WEBVPN"
      );

      this.firstLogins.unshift("不启用");
    },
    dealQrcode() {
      let allLoginMehods = this.allSources.concat(this.allMethods);
      this.wechatMethods = WechatFilter(allLoginMehods);
      let wechatShift =
        this.wechatMethods.length > 0 ? "不启用" : "无可用的认证方式";
      this.wechatMethods.unshift(wechatShift);

      this.wechatEnterpriseMethods = WechatEnterpriseFilter(allLoginMehods);
      let wechatEnterpriseShift =
        this.wechatEnterpriseMethods.length > 0 ? "不启用" : "无可用的认证方式";
      this.wechatEnterpriseMethods.unshift(wechatEnterpriseShift);

      this.dingTalkMethods = DingTalkFilter(allLoginMehods);
      let dingTalkShift =
        this.dingTalkMethods.length > 0 ? "不启用" : "无可用的认证方式";
      this.dingTalkMethods.unshift(dingTalkShift);

      this.larkMethods = LarkFilter(allLoginMehods);
      let larkShift =
        this.larkMethods.length > 0 ? "不启用" : "无可用的认证方式";
      this.larkMethods.unshift(larkShift);
    },
    dealLinkMehods() {
      let allLoginMehods = this.allSources.concat(this.allMethods);

      this.firstLinks = allLoginMehods.filter(
        item =>
          item.type === "CAS" ||
          item.type === "CAS_REST_API" ||
          item.type === "OAUTH2" ||
          item.type === "LARK" ||
          item.type === "HTTP_BASIC"
      );
      this.firstLinks.unshift("不启用");
    }
  },
  watch: {
    firstLogins() {
      return (this.secondLogins = this.firstLogins.filter(
        item => item.external_id !== this.firstLogin
      ));
    },
    secondLogins() {
      return (this.thirdLogins = this.secondLogins.filter(
        item => item.external_id !== this.secondLogin
      ));
    },
    firstLinks() {
      return (this.secondLinks = this.firstLinks.filter(
        item => item.external_id !== this.firstLink
      ));
    },
    secondLinks() {
      return (this.thirdLinks = this.secondLinks.filter(
        item => item.external_id !== this.secondLink
      ));
    },
    thirdLinks() {
      return (this.forthLinks = this.thirdLinks.filter(
        item => item.external_id !== this.thirdLink
      ));
    }
  },
  computed: {
    loginFontStyle() {
      const { loginFontColor, loginFontMenu } = this;
      return {
        backgroundColor: loginFontColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: loginFontMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    },
    loginHighLightStyle() {
      const { loginHighLight, HighLightMenu } = this;
      return {
        backgroundColor: loginHighLight,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: HighLightMenu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-subheader {
  color: rgb(44, 62, 80);
}

/deep/.v-input__slot {
  padding-left: 10px;
}

/deep/ .v-label {
  font-size: 14px;
  color: rgb(44, 62, 80);
}
/deep/ .v-select__selections {
  font-size: 14px;
}

/deep/ .v-text-field__slot {
  font-size: 14px;
}

/deep/ .v-input__slot {
  padding-left: 5px;
}

.slider-item {
  /deep/ .v-input__slot {
    padding-left: 0px;
  }
}

.hint-style {
  padding-left: 30%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.auth-display {
  /deep/ .v-input--selection-controls {
    margin-top: 0;
    padding-top: 4px;
  }
}

.support-style {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Microsoft YaHei !important;
}
</style>
